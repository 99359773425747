<template>
  <div class="root">
    <div v-if="loading" class="progress-loading">
      <div class="progress-bar-loading" :style="{'width':'40%'}"></div>
    </div>
    <div class="core card-model">
      <div class="icon-model" v-bind:style="[loading ? {backgroundColor: colorLoading} : {backgroundColor: color}]">
        <img :src="require(`../assets/img/${icon}`)"/>
      </div>
      <div>
        <span class="number">{{ title }}</span>
        <span class="label">{{ subtitle }}</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CardProgress',
  props: {
    loading: Boolean,
    title: String,
    subtitle: String,
    icon: String,
    color: String,
  },
  data:  function () {
    return {
      colorLoading: '#e3e8ec'
    }
  }
}
</script>

<style>
.root{
  width: 100%;
  margin-right: 0.7rem;
  padding-right: 10px;
}

.card-model {
  background-color: #ffffff;
  margin: 0 0.7rem;
  padding: 0.8rem;
  width: 100%;
  align-items: center;
  border-radius: 0.2rem;
  display: flex;
}

.icon-model {
  align-items: center;
  border-radius: 0.2rem;
  display: flex;
  height: 3.5rem;
  justify-content: center;
  margin-right: 1rem;
  text-align: center;
  width: 3.5rem;
}

div.card-model .number {
  display: block;
  font-size: 1.8em;
  font-weight: 700;
  font-family: Nunito;
}

div.card-model .label {
  color: #98a6ad;
  font-family: Nunito;
  font-weight: 400;
}

.progress-loading {
  height: 3px;
  background-color: #47B0FF;
  position: relative;
  width: 100%;
  margin-left: 11px;
  margin-right: 10px;
  border-radius: 1px;

}

.progress-loading .progress-bar-loading {
  position: absolute;
  height: 100%;
  background-color:#008FFB;
  animation:borealisBar 1s linear infinite;
}

@keyframes borealisBar {
  0% {
    left:0%;
    right:100%;
    width:0%;
  }
  10% {
    left:0%;
    right:50%;
    width:75%;
  }
  90% {
    right:0%;
    left:75%;
    width:25%;
  }
  100% {
    left:100%;
    right:10%;
    width:0%;
  }
}
</style>