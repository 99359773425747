<template>
  <div class="rootBigSaldo">
    <div v-if="loading" class="progress-loading-saldo-card">
      <div class="progress-bar-loading-saldo-card " :style="{'width':'40%'}"></div>
    </div>
    <div class="roas card-flex-align-saldo">
      <div class="icon-model-saldo-restante" style="float: left"  v-bind:style="[loading ? {backgroundColor: colorLoading} : {backgroundColor: color}]">
        <img src="../assets/img/icon-saldo-restante.svg" />
      </div>
      <div>
        <span class="number">R$ {{ title }}</span>
        <span class="label">{{ subtitle }}</span>
        <br>
        <a href="https://ads.tiktok.com/" class="link-saldo">Adicionar saldo</a> <img src="../assets/img/icon-link.png">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardSaldoDashboard',
  props: {
    loading: Boolean,
    title: String,
    subtitle: String,
    color: String,
  },
  data:  function () {
    return {
      colorLoading: '#e3e8ec'
    }
  }
}
</script>

<style>

.rootBigSaldo{
  width: 100%;
  margin-right: 0.7rem;
  margin-top: 20px;
}

span.percentual{
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #6C757D
}

.icon-model-saldo-restante {
  align-items: center;
  border-radius: 0.2rem;
  display: flex;
  height: 36px;
  justify-content: center;
  margin-right: 1rem;
  text-align: center;
  width: 35.77px;
}

.roas .icon-model-saldo-restante {
  background-color: #F1556C;
}

.card-flex-align-saldo {
  background-color: #ffffff;
  padding: 24px 1rem 1rem 1rem;
  width: 100%;
  min-height: 154px;
  align-items: center;
  border-radius: 0.2rem;
  text-align: end;
}

div.summary .ativas .icon-model-saldo-restante {
  background-color: #1abc9c40;
}

div.card-flex-align-saldo .label {
  color: #98a6ad;
  font-family: Nunito;
  font-size: 400;
}

div.card-flex-align-saldo .number {
  display: block;
  font-size: 1.8em;
  font-weight: 700;
  margin-bottom: 0.3rem;
  font-family: Nunito;
}

.progress-loading-saldo-card {
  height: 3px;
  background-color: #47B0FF;
  position: relative;
  width: 100%;
  margin-left: 0px;
  margin-right: 10px;
  border-radius: 1px;
}

.link-saldo {
  font-family: 'Nunito Sans';
  font-size: 12px;
  text-decoration:none;
  color: #008FFB;
}

.progress-loading-saldo-card  .progress-bar-loading-saldo-card  {
  position: absolute;
  height: 100%;
  background-color:#008FFB;
  animation:borealisBar 1s linear infinite;
}

@keyframes borealisBar {
  0% {
    left:0%;
    right:100%;
    width:0%;
  }
  10% {
    left:0%;
    right:50%;
    width:75%;
  }
  90% {
    right:0%;
    left:75%;
    width:25%;
  }
  100% {
    left:100%;
    right:10%;
    width:0%;
  }
}
</style>