<template>
  <div>

<div class="header">
  <div class="title-dash">
    <h1 class="font-dash">Visão geral</h1>
  </div>
    <div class="time-filter">
      <section>
        <button class="button-line" v-bind:class="{'button-select': interval == 7, 'button-no-select': interval != 7}"  v-on:click="updateData(7)" id="legal" autofocus>7 dias</button>
        <button class="button-line-left" v-bind:class="{'button-select': interval == 30, 'button-no-select': interval != 30}" v-on:click="updateData(30)" id="individual">30 dias</button>
      </section>
    </div>
</div>

    <div class="container-now">
      <CardProgress :loading="loadingCliques" color="#1abc9c40" :title="cliques.toString()" subtitle="Cliques" icon="icon-cliques.svg"></CardProgress>
      <CardProgress :loading="loadingCusto" color="rgba(74, 129, 212, 0.25)" :title="`${custo}`" subtitle="Custo" icon="icon-custo.svg"></CardProgress>
      <CardProgress :loading="loadingConversoes" color="#f1556c40" :title="`${conversoes}`" subtitle="Conversões" icon="icon-conversoes.svg"></CardProgress>
      <CardProgress :loading="loadingVendas" color="#f7b84b40" :title="`${vendido}`" subtitle="Total vendido (R$)" icon="icon-vendidos.svg"></CardProgress>
    </div>
    <div class="summary">
      <div class="flex-container-p">
        <div class="vert">
          <CardRoasDashboard :loading="loadingRoas" color="#1ABC9C" :title="`${roas}`" subtitle="ROAS" icon="icon-cliques.svg" :percentual="`${percentualRoas}`"></CardRoasDashboard>
          <CardSaldoDashboard :loading="loadingSaldoRestante" color="#F1556C" :title="`${saldo}`" subtitle="Saldo restante"></CardSaldoDashboard>
        </div>
        <div class="flex-container-g">
          <div class="graphic-t card-graphic">
            <template>
              <div id="chart">
                <apexchart
                    width="100%"
                    height="200%"
                    type="line"
                    :options="chartOptions"
                    :series="series"
                ></apexchart>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="help-bottom">
      <a href="https://atendimento.tray.com.br/hc/pt-br/articles/4570159027732-Integra%C3%A7%C3%A3o-TikTok" target="_blank"><p> <img :src="require(`../assets/img/icon-help.svg`)"/> Preciso de ajuda</p></a>
    </div>
  </div>
</template>

<script>

import CardProgress from './CardProgress.vue'
import CardRoasDashboard from './CardRoasDashboard.vue'
import CardSaldoDashboard from './CardSaldoDashboard.vue'

export default {
  name: "Dashboard",
  beforeMount() {
    this.updateData(7);
    this.getBalance();
  },
  mounted() {
    document.title = "Página Inicial";
  },
  components: {CardProgress, CardRoasDashboard, CardSaldoDashboard},
  data: function () {
    return {
      vendido: 0,
      conversoes: 0,
      custo: 0,
      visualizacoes: 0,
      cliques: 0,
      interval: 0,
      roas: 0,
      saldo: 0,
      percentualRoas: '0%',
      valuePercentualRoas: 0,
      dataSales: [],
      dataCampaigns: [],
      loadingCliques: true,
      loadingCusto: true,
      loadingConversoes: true,
      loadingVendas: true,
      loadingRoas: true,
      loadingSaldoRestante: true,

      series: [],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          toolbar: {
            show: false
          }
        },
        colors:  ["#F1556C40", "#1ABC9C40", "#F7B84B40", "#98A6AD40"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth'
        },
        title: {
          text: '',
          align: 'left'
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          },
        },
        markers: {
          size: 1
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: ''
          },
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
          floating: false,
          offsetY: 8,
          offsetX: 5
        }
      },
    };
  },
  methods: {
    async updateData(interval) {
      this.interval = interval;
      this.vendido = 0;
      this.conversoes = 0;
      this.custo = 0;
      this.visualizacoes = 0;
      this.cliques = 0;
      this.roas = 0;
      this.saldo = 0;
      this.percentualRoas = 0;
      this.valuePercentualRoas = 0;
      this.loadingCliques= true,
          this.loadingCusto= true,
          this.loadingConversoes= true,
          this.loadingVendas= true,
          this.loadingRoas = true,
          this.loadingSaldoRestante = true,

          this.getSales(interval);
      this.getBalance();
    },
    getBalance(){
      let storeId = this.$cookies.get("Authorization");
      this.$http
          .get("tiktok/campaigns/balance", {
            headers: { Authorization: storeId },
          })
          .then((res) => {
            let balance = res.data;
            this.saldo = balance.toLocaleString('pt-br', {minimumFractionDigits: 2});
            this.loadingSaldoRestante = false;
          })
          .catch((error) => {
            this.loadingSaldoRestante = false;
            if (error.response) {
              console.log(error.response.status);
            }
          });
    },
    getSales(interval = 7) {
      let storeId = this.$cookies.get("Authorization");
      this.$http
          .post(
              "tray/consolidate/sales?interval=" + interval,
              {},
              { headers: { Authorization: storeId } }
          )
          .then((res) => {
            this.dataSales = res.data;
            this.getReportCampaigns(interval);
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.status);
            }
          });
    },
    getReportCampaigns: function (interval = 7) {
      let storeId = this.$cookies.get("Authorization");
      this.$http
          .get("tiktok/campaigns/report/?interval=" + interval, {
            headers: { Authorization: storeId },
          })
          .then((res) => {
            this.dataCampaigns = res.data;
            this.setData();
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.status);
            }
          });
    },
    setData: function (){
      let spend = [];
      let conversion = [];
      let clicks = [];
      let sales = [];

      this.loadingCliques = false;
      this.loadingCusto = false;
      this.loadingConversoes = false;
      this.loadingVendas = false;
      this.loadingRoas = false;

      let p = this.dataCampaigns;
      let days = [];

      for (let key in p) {
        if (p.hasOwnProperty(key)) {

          days.push(p[key].date);

          let spendTmp = parseFloat(p[key].spend);
          let conversionTmp = parseFloat(p[key].conversion);
          let clicksTmp = parseFloat(p[key].clicks);

          this.conversoes += conversionTmp;
          this.cliques += clicksTmp;
          this.custo += spendTmp;

          spend.push(spendTmp);
          conversion.push(conversionTmp);
          clicks.push(clicksTmp);
        }
      }

      let s = this.dataSales;

      for (let key in s) {
        if (this.dataSales.hasOwnProperty(key)) {
          sales.push(s[key].value)
          this.vendido += s[key].value;
        }
      }

      if (this.vendido && this.custo) {
        this.roas = (this.vendido / this.custo).toFixed(2);
        this.valuePercentualRoas = this.roas * 100;
        this.percentualRoas = this.valuePercentualRoas+'%';
      }

      this.vendido = this.vendido.toLocaleString('pt-br', {minimumFractionDigits: 2, style: 'currency', currency: 'BRL'});
      this.custo = this.custo.toLocaleString('pt-br', {minimumFractionDigits: 2, style: 'currency', currency: 'BRL'});

      this.series = [
        {
          name: "Custo",
          data: spend,
        },
        {
          name: "Cliques",
          data: clicks,
        },
        {
          name: "Vendas",
          data: sales,
        },
        {
          name: "Conversão",
          data: conversion,
        },
      ];

      this.chartOptions = {
        xaxis: {
          categories: days
        },
      };
    }
  },
};

</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
  letter-spacing: 0;
}
.container-now {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1.3rem;
  margin-left: -0.8%;
  margin-right: -0.5%;
}

button {
  border: none;
  outline: none;
  padding: 5px 5px;
}

.button-select {
  background: #6d767e;
  color: #f3f4f6;
}

.button-no-select {
  color: #6C757D;
}

.button-line {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  border-bottom: 1px solid #6d767e;
  border-left: 1px solid #6d767e;
  border-top: 1px solid #6d767e;
  border-radius: 1px;
  width: 52.2px;
  height: 24.4px;
  align-items: center;
  padding: 2px;
  margin-top: 20px;
  border-radius: 2.4px 0px 0px 2.4px;
}

.button-line-left {
  align-items: center;
  width: 52.2px;
  height: 24.4px;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  border-bottom: 1px solid #6d767e;
  border-top: 1px solid #6d767e;
  border-right: 1px solid #6d767e;
  border-radius: 1px;
  padding: 2px;
  border-radius: 0px 2.4px 2.4px 0px;
}

h1 {
  align-self: center;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 0rem;
  margin-top: 1rem;
}

div.filtro-campanha {
  margin-bottom: 1.6rem;
  padding-right: 1em;
  text-align: right;
}

div.summary {
  display: flex;
  justify-content: space-around;
  margin-bottom: 2rem;
}

div.summary div:first-child {
  margin-left: 0;
}

div.summary div:last-child {
  margin-right: 0;
}



div.card-graphic .label {
  color: #98a6ad;
}

div.card-graphic .number {
  display: block;
  font-size: 1.8em;
  font-weight: 600;
  margin-bottom: 0.3rem;
}

div.card-graphic {
  background-color: #ffffff;
  margin: 0 0.7rem;
  padding: 1rem;
  align-items: center;
  border-radius: 0.2rem;
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
  width: 100%;
  height: 100%;
}

div.summary .graphic-t .icon-campanha {
  background-color: #f7b84b40;
}

.vert {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: normal;
  align-items: normal;
  align-content: normal;
  width: 24%;
}

.flex-container-p {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
}

.flex-container-g {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: normal;
  align-items: normal;
  align-content: normal;
  width: 75%;
  margin-left: 20px;
}
.font-all {
  font-family: 'Nunito';
}

.help-bottom {
  background-color: #DEE2E6;
  height: 36px;
  color: #6C757D;
  border-radius: 3px;
  padding: 12px;
  position: relative;
}
.help-bottom a {
  text-decoration: none;
  color: #6C757D;
}

.help-bottom p {
  font-family: 'Nunito';
  font-weight: 400;
}

.font-dash {
  font-family: 'Nunito';
  font-weight: 600;
}
.help-bottom p {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: 'Nunito';
  font-style: normal;
  font-size: 14px;
}
.header {
  display: inline-block; 
  align-items: baseline;
  margin-bottom: 10px;
  width: 100%;
  padding: 2px;
}
.title-dash {
  float: left;
}
.time-filter {
  float: right;
}
</style>

